import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import UserLinks from "../components/UserLinks/UserLinks";
import UserInfo from "../components/UserInfo/UserInfo";
// import UserInfo from "../components/UserInfo/UserInfo";

class Index extends React.Component {
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <div className="index-container">
          <Helmet title={config.siteTitle} />
          <SEO />
          <h1>{config.siteTitle}</h1>
          <UserLinks config={config} labeled />
          <section className="posts">
            <h2>Latest articles</h2>
            <PostListing postEdges={postEdges} />
          </section>
          {/* <br /> */}
          {/* <UserInfo config={config} /> */}
          {/* <a href="/about">about</a> */}
        </div>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
